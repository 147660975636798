import React, { useEffect, useState } from 'react';
import * as I from 'computing-den-lib/dist/client/Icon.jsx';
import type { GalleryImageData } from './types.js';

type GalleryDialogProps = {
  onClose: () => any;
  index: number;
  images: GalleryImageData[];
};

export default function GalleryDialog(props: GalleryDialogProps) {
  const [curIndex, setCurIndex] = useState(props.index);

  useEffect(() => {
    getGalleryDialog()!.showModal();
  }, []);

  useEffect(() => {
    getGalleryDialog()!.addEventListener('close', props.onClose);
    return () => getGalleryDialog()?.removeEventListener('close', props.onClose);
  }, [props.onClose]);

  useEffect(() => {
    function callback(e: KeyboardEvent) {
      if (e.key === 'ArrowRight' || (e.key === ' ' && !e.shiftKey)) {
        e.preventDefault();
        e.stopPropagation();
        goToNext();
      } else if (e.key === 'ArrowLeft' || (e.key === ' ' && e.shiftKey)) {
        e.preventDefault();
        e.stopPropagation();
        goToPrev();
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    window.addEventListener('keyup', callback);
    return () => window.removeEventListener('keyup', callback);
  }, [goToNext, goToPrev]);

  function onClose() {
    getGalleryDialog()?.close();
  }

  function goToNext() {
    setCurIndex((curIndex + 1) % props.images.length);
  }

  function goToPrev() {
    setCurIndex((curIndex - 1 + props.images.length) % props.images.length);
  }

  function nextClicked(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    goToNext();
  }

  function prevClicked(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    goToPrev();
  }

  function dialogClicked(e: React.MouseEvent) {
    if (e.target === document.getElementById('gallery-dialog')) {
      onClose();
    }
  }

  const cur = props.images[curIndex];

  return (
    <dialog id="gallery-dialog" onClick={dialogClicked}>
      <button className="close" onClick={onClose}>
        <I.IconX />
      </button>
      <button className="next" onClick={nextClicked}>
        <I.IconChevronRight />
      </button>
      <button className="prev" onClick={prevClicked}>
        <I.IconChevronLeft />
      </button>
      <img src={cur.src} />
      {/*
        <button autoFocus onClick={props.onClose}>
          Close
        </button>
          */}
      <div className="caption">{cur.caption}</div>
    </dialog>
  );
}

function getGalleryDialog(): HTMLDialogElement | undefined {
  return document.getElementById('gallery-dialog') as HTMLDialogElement | undefined;
}
