import { createRoot } from 'react-dom/client';
import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import Navbar from './Navbar.jsx';
import Contact from 'computing-den-lib/dist/client/Contact.jsx';
import Intro from './Intro.jsx';
import {
  useRestoreScroll,
  useGoToHash,
  useDragScroll,
  useClickWithoutDrag,
  getBookHref,
} from 'computing-den-lib/dist/client/misc.jsx';
import * as I from 'computing-den-lib/dist/client/Icon.jsx';
import times from 'lodash/times.js';
import GalleryDialog from './GalleryDialog.jsx';
import type { GalleryImageData } from './types.js';

export function ImageCarousel(props: {
  images: GalleryImageData[];
  dragArea: RefObject<HTMLElement>;
  scrollArea: RefObject<HTMLElement>;
}) {
  const [dialogCurImageIndex, setDialogCurImageIndex] = useState<number | undefined>();

  useDragScroll({ dragArea: props.dragArea, scrollArea: props.scrollArea });

  const openDialog = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setDialogCurImageIndex(Number(e.currentTarget.dataset.index));
  }, []);

  const closeDialog = useCallback(() => {
    setDialogCurImageIndex(undefined);
  }, []);

  return (
    <div className="image-carousel" ref={props.scrollArea as RefObject<HTMLDivElement>}>
      {dialogCurImageIndex !== undefined && (
        <GalleryDialog images={props.images} index={dialogCurImageIndex} onClose={closeDialog} />
      )}
      {props.images.map((data, i) => (
        <Image key={i} data={data} index={i} onClick={openDialog} />
      ))}
    </div>
  );
}

export function ImageCarouselScrollIndicator(props: { scrollArea: React.RefObject<HTMLElement> }) {
  const [pages, setPages] = useState(0);
  const [curPage, setCurPage] = useState(0);

  useEffect(() => {
    function update() {
      const area = props.scrollArea.current!;
      setPages(Math.ceil(area.scrollWidth / area.clientWidth));
      setCurPage(Math.ceil(area.scrollLeft / area.clientWidth));
    }
    update();
    props.scrollArea.current!.addEventListener('scroll', update);
    window.addEventListener('resize', update);
    return () => {
      props.scrollArea.current!.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, []);

  return (
    <div className="image-carousel-scroll-indicator">
      {times(pages, i => (
        <span className={curPage === i ? 'cur' : ''} />
      ))}
    </div>
  );
}

function Image(props: { data: GalleryImageData; index: number; onClick: (e: React.MouseEvent<HTMLElement>) => any }) {
  const [onMouseDown, onMouseUp] = useClickWithoutDrag(props.onClick);
  return (
    <div className="image">
      <img
        src={props.data.src}
        data-index={props.index}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        className={props.data.withoutShadow ? 'without-shadow' : ''}
      />
      <div className="caption">{props.data.caption}</div>
    </div>
  );
}

export function ImageCarouselScrollButtons(props: { scrollArea: React.RefObject<HTMLElement> }) {
  function getChildDiffX() {
    const area = props.scrollArea.current!;
    return (area.childNodes[1] as HTMLElement).offsetLeft - (area.childNodes[0] as HTMLElement).offsetLeft;
  }

  function nextClicked() {
    const area = props.scrollArea.current!;
    if (window.innerWidth <= 800) {
      area.scrollBy({ left: getChildDiffX(), behavior: 'smooth' });
    } else {
      area.scrollBy({ left: area.clientWidth, behavior: 'smooth' });
    }
  }

  function prevClicked() {
    const area = props.scrollArea.current!;
    if (window.innerWidth <= 800) {
      area.scrollBy({ left: -getChildDiffX(), behavior: 'smooth' });
    } else {
      area.scrollBy({ left: -area.clientWidth, behavior: 'smooth' });
    }
  }

  return (
    <div className="image-carousel-scroll-buttons">
      <button className="prev" onClick={prevClicked}>
        <I.IconChevronLeft />
      </button>
      <button className="next" onClick={nextClicked}>
        <I.IconChevronRight />
      </button>
    </div>
  );
}
