import React from 'react';
export type IconCardData = { icon: React.ReactNode; heading: string; desc: React.ReactNode };

export default function CardWithIconGrid(props: { children: React.ReactNode; blue?: boolean; green?: boolean }) {
  return (
    <div className={`icon-card-grid ${props.blue ? 'blue' : ''} ${props.green ? 'green' : ''}`}>{props.children}</div>
  );
}

export function IconCard(props: { heading: React.ReactNode; icon: React.ReactNode; children: React.ReactNode }) {
  return (
    <div className="icon-card">
      {props.icon}
      <div>
        <h2>{props.heading}</h2>
        {props.children}
      </div>
    </div>
  );
}

IconCard.Desc = function IconCardDesc(props: { children: React.ReactNode }) {
  return <p className="desc">{props.children}</p>;
};

IconCard.Footer = function IconCardFooter(props: { children: React.ReactNode }) {
  return <p className="footer">{props.children}</p>;
};
